@import "../../utils/styles/variables.scss";

.moreButton {
  display: none;
}

@media only screen and (max-width: 767px) { 
  .moreButton {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    span {
      font-family: $Alternates;
      font-size: 8px;
    }
    &__content {
      display: flex;
      align-items: center;
    }
  }
  .collapse {
    svg {
      transform: rotate(180deg);
    }
  }
}