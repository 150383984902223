@import "../../utils/styles/variables.scss";

.about {

  .sectionHeader {
    &__message {
      max-width: 809px;
    }
  }
  &__timeLine {
    margin-top:55px;
    position: relative;

    .timeLine__line {
      height: 3px;
      background-color: #919693;
    }
    
    .timeLine__upperDates {
      display: flex;
      justify-content: space-between;

      .timeLine__text {
        padding:32px 0 18px 81px;

        .text__header {
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          color: $darkBrown;
        }
        .text__subHeader {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: $darkBrown;
        }
      }
      span {
        font-family: $Montserrat;
        font-weight: 400;
        font-size: 20px;
      }
      
      .timeLine__firstElement {
        width: 321px;
        height: 208px;
        margin-left: 165px;

        .timeLine__date {
          padding:28px 20px;
          border: 2px solid  #d6606d;
          border-radius: 50%;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(217deg, rgba(214, 96, 109, 0.85) 0%, #e59047 36.73%, rgba(229, 185, 71, 0.75) 76.03%, rgba(239, 178, 45, 0.5) 100%, rgba(239, 178, 45, 0) 100%);
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 156px;
            background: #d6606d;
            right: 50%;
            top: 80px;
            position: absolute;
          }
        }
        .timeLine__text {
          padding:20px 0 18px 81px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 175px;
        }
        
      }
      .timeLine__secondElement {
        width: 321px;
        height: 190px;
        margin-left: 165px;
        top: 46px;
        position: relative;

        .timeLine__date {
          padding:28px 18px;
          border: 1px solid  #9374b9;
          border-radius: 50%;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(221deg, #557bbb 0%, rgba(147, 116, 185, 0.75) 54.4%, rgba(195, 108, 164, 0.5) 84.77%);
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 110px;
            background: #9374b9;
            right: 50%;
            top: 80px;
            position: absolute;
          }
        }

      }
      .timeLine__thirdElement {
        width: 321px;
        height: 190px;
        margin-left: 165px;
        top: -16px;
        position: relative;

        .timeLine__date {
          padding:29px 19px;
          border-radius: 50%;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(132deg, rgba(0, 114, 106, 0.85) 8.5%, rgba(85, 123, 187, 0.8) 35%, #c36ca4 89%);
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 173px;
            background:  #c36ca4;
            right: 50%;
            top: 80px;
            position: absolute;
          }
        }

      }
    }

    .timeLine__lowerDates {
      display: flex;
      justify-content: center;
      column-gap: 254px;

      .timeLine__leftText {
        width: 220px;
        height: 84px;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .text__header {
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          color: $darkBrown;
        }
        .text__subHeader {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: $darkBrown;
        }
      }

      .timeLine__rightText {
        width: 220px;
        height: 84px;
        text-align: start;
        position: relative;
        right: -250px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .text__header {
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          color: $darkBrown;
        }
        .text__subHeader {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: $darkBrown;
        }
      }

      .timeLine__text {
        padding:32px 0 18px 81px;

        .text__header {
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          color: $darkBrown;
        }
        .text__subHeader {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: $darkBrown;
        }

      }
      .timeLine__firstElement {
        width: 475px;
        height: 334px;
        text-align: center;

        .timeLine__date {
          padding:29px 18px;
          border: 1px solid  rgba(0, 95, 163, 0.8);
          border-radius: 50%;
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(133deg, rgba(0, 114, 106, 0.5) 7.01%, rgba(0, 121, 140, 0.75) 39.86%, rgba(0, 124, 170, 0.85) 61.22%, #007686 80.03%);
          position: relative;
          top: 280px;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 253px;;
            background: rgba(0, 95, 163, 0.8);
            right: 50%;
            top: -254px;
            position: absolute;
          }
        }
      }
      .timeLine__firstElementMobile {
        width: 321px;
        height: 208px;
        display: none;
        margin-left: 165px;

        .timeLine__date {
          padding:28px 20px;
          border: 1px solid  rgba(0, 95, 163, 0.8);
          border-radius: 50%;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(133deg, rgba(0, 114, 106, 0.5) 7.01%, rgba(0, 121, 140, 0.75) 39.86%, rgba(0, 124, 170, 0.85) 61.22%, #007686 80.03%);
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 156px;
            background: rgba(0, 95, 163, 0.8);
            right: 50%;
            top: 80px;
            position: absolute;
          }
        }
        
      }
      .timeLine__secondElement {
        width: 286px;
        height: 241px;

        .timeLine__date {
          padding:29px 18px;
          border: 1px solid  #efb22d;
          border-radius: 50%;
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.02em;
          color: #fff;
          background:  linear-gradient(360deg, rgba(147, 116, 185, 0.85) 20.83%, rgba(195, 108, 164, 0.75) 55.87%, rgba(239, 178, 45, 0.5) 100%);
          position: relative;
          top: 188px;

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 160px;
            background: #efb22d;
            right: 50%;
            top: -160px;
            position: absolute;
          }
        }
      }
    }
  }
  

}
@media only screen and (max-width: 1600px) {
  .about {

    &__timeLine {
     
  
      .timeLine__upperDates {
        display: flex;
        justify-content: space-between;
  
        
        .timeLine__firstElement {
          margin-left: 70px;
        }
        .timeLine__secondElement {
          margin-left: 70px;
  
        }
        .timeLine__thirdElement {
          margin-left: 70px;
  
        }
      }
    }
  }
}
@media only screen and (max-width: 1380px) { 
  .about {
    .sectionHeader {
      &__message {
        max-width: 340px;
      }
    }
    &__timeLine {
      position: relative;
      height: 643px;
      &:after {
        content:'';
        position: absolute;
        width:3px;
        height: 642px;
        background-color:#919693;
        left: 50%;
        transform: translateX(-50%);
      }

      .timeLine__line {
        display: none;
      }
      .timeLine__upperDates {
  
        .timeLine__text {
          padding: 0;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .text__header {
            font-size: 15px;
          }

          .text__subHeader {
            font-size: 13px;
            width:200px;
            display: block;
            br {
              &:first-child {
                display: none;
              }
            }
          }
        }

        .timeLine__firstElement {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          padding-right: 229px;

          .timeLine__date {
            font-size: 15px;
            padding:21px 14px 19px 14px;

            &:after {
              width: 68px;
              height: 2px;
              right: -70px;
              top: 30px;
            }
          }
          .timeLine__text {
            height: 100px;
            padding: 0;
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            .text__subHeader {
              width:200px;
            }
          }
        }
        .timeLine__secondElement {
          position: absolute;
          display: flex;
          align-items: center;
          max-height: 62px;
          flex-direction: row-reverse;
          left: 50%;
          top: 50%;
          margin:0;
          transform: translate(-50%, -50%);
          padding-right: 229px;

          .timeLine__date {
            font-size: 15px;
            padding:21px 14px 19px 14px;

            &:after {
              width: 68px;
              height: 2px;
              right: -69px;
              top: 30px;
            }
          }
          .timeLine__text {
            height: 62px;
            display: block;
            .text__subHeader {
              br {
                &:first-child {
                  display: block;
                }
              }
            }
          }
        }
        .timeLine__thirdElement {
          position: absolute;
          display: flex;
          align-items: center;
          max-height: 62px;
          flex-direction: row-reverse;
          left: 50%;
          top:90%;
          margin:0;
          transform: translate(-50%,-90%);
          padding-right: 229px;

          .timeLine__date {
            font-size: 15px;
            padding:21px 14px 19px 14px;

            &:after {
              width: 68px;
              height: 2px;
              right: -68px;
              top: 30px;
            }
          }
          .timeLine__text {
            height: 62px;
            display: block;
            .text__subHeader {
              br {
                &:first-child {
                  display: block;
                }
              }
            }
          }
        }
      }
      .timeLine__lowerDates {
        .timeLine__text {
          padding: 0;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .text__header {
            font-size: 15px;
          }

          .text__subHeader {
            font-size: 13px;
            width:200px;
            display: block;
            
          }
        }
        .timeLine__firstElement {
          display: none;
        }
        .timeLine__firstElementMobile {
          position: absolute;
          display: flex;
          align-items: center;
          padding-left: 145px;
          top:25%;
          transform: translateY(-25%);
          .timeLine__date {
            font-size: 15px;
            padding:21px 14px 19px 14px;
            
            &:after {
              width: 68px;
              height: 2px;
              right: 62px;
              top: 30px;
            }
          }
          .timeLine__text {
            margin-right: 0;
            margin-left: 15px;
            height: 100px;
            justify-content: space-between;
            .text__subHeader {
              width: 160px;
            }
          }
        }
        .timeLine__secondElement {
          position: absolute;
          display: flex;
          align-items: center;
          max-height: 62px;
          padding-left: 209px;
          top:75%;
          transform: translateY(-75%);

          .timeLine__date {
            font-size: 15px;
            padding:21px 14px 19px 14px;
            top:0;
            &:after {
              width: 65px;
              height: 2px;
              right: 65px;
              top: 30px;
            }
          }
          .timeLine__text {
            height: 62px;
            display: block;
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) { 
  .about {
    .sectionHeader {
      &__message {
        width: 118px;
      }
    }

    &__timeLine {
      position: relative;
      height: 375px;
      
      &:after {
        width: 2px;
        left: 0;
        transform: translateX(0%);
        height: 375px;
      }

      .timeLine__line {
        display: none;
      }
      .timeLine__upperDates {
        
        .timeLine__text {
          .text__header {
            font-size: 12px;
            
          }
          .text__subHeader {
            font-size: 10px;
            br {
              display: block;
            }
          }
          br {
            display: none;
          }
        }
        

        .timeLine__firstElement {
          flex-direction: row;
          padding-left: 25px;
          height: auto;
          
          .timeLine__date {
            font-size: 12px;
            padding:12px 7px;
            margin-right: 16px;

            &:after {
              width: 33px;
              height: 2px;
              right: 40px;
              top: 20px;
            }
          }
          .timeLine__text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 72px;
              .text__subHeader{
                width: 201px;
              }
          }
        }

        .timeLine__secondElement {
          flex-direction: row;
          padding-left: 25px;
          
          .timeLine__date {
            font-size: 12px;
            padding:13px 7px;
            margin-right: 16px;

            &:after {
              width: 34px;
              height: 2px;
              right: 41px;
              top: 20px;
            }
          }
          .timeLine__text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 40px;
            .text__subHeader {
              br {
                &:first-child {
                  display: none;
                }
              }
            }
          }
        }
        .timeLine__thirdElement {
          flex-direction: row;
          padding-left: 25px;
          top:97%;
          transform: translate(-50%,-97%);

          .timeLine__date {
            font-size: 12px;
            padding: 13px 6px;
            margin-right: 16px;
            border: 1px solid #c36ca4;

            &:after {
              width: 33px;
              height: 2px;
              right: 42px;
              top: 20px;
            }
          }
          .timeLine__text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 45px;
            .text__subHeader {
              width: 235px;
              br {
                &:first-child {
                  display: none;
                }
              }
            }
          }
          
        }
      }
      .timeLine__lowerDates {
        .timeLine__text {
          .text__header {
            font-size: 12px;
          }
          .text__subHeader {
            font-size: 10px;
          }
          br {
            display: none;
          }
        }
      
        .timeLine__firstElementMobile {
          padding-left: 7px;
          margin: 0;
          height: auto;
          top: 27%;
          left: 27px;
          transform: translateY(-27%);
          
          .timeLine__date {
            font-size: 12px;
            padding:12px 7px;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        
            &:after {
              width: 34px;
              height: 2px;
              right: 41px;
              top: 20px;
            }
          }
          .timeLine__text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 60px;

            br {
              display: none;
            }

            .text__subHeader {
              width: 226px;
            }
          }
        }
        .timeLine__secondElement {
          padding-left: 7px;
          margin: 0;
          height: auto;
          top:72%;
          transform: translateY(-72%);

          .timeLine__date {
            font-size: 12px;
            padding:13px 6px;

            &:after {
              height: 2px;
              right: 42px;
              top: 20px;
              width: 33px;
            }
          }
          .timeLine__text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 40px;

            .text__subHeader {
              width: 225px;
            }
          }
        }
      }
    }
  }
  .companiesSlider {
  
    .company__icon {
      margin: 0 20px;
    }
  }
}
@-moz-document url-prefix() {
  
@media only screen and (max-width: 767px) { 
  .about {

    &__timeLine {

      .timeLine__lowerDates {
      
        .timeLine__firstElementMobile {
          
          .timeLine__text {
            
            .text__subHeader {
              width: 235px;
            }
          }
        }
       
      }
    }
  }
}
}

.companiesSlider {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin:55px auto 0;
  
  
  &:hover div {
    animation-play-state: paused;
  }

  .company__icon {
    margin: 0 50px;
  }

  .slider__list {
    flex: 0 0 auto;
    min-width: auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear infinite;
    animation-play-state: play;
    animation-direction: reverse;
    transform: translateX(0%);
    
    @keyframes scroll {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
}
