@import "../../utils/styles/variables.scss";

.main {
  display:flex;
  column-gap:33px;
  margin-bottom: 25px;

  p {
    font-family: $Montserrat;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.02em;
    text-align: right;
    color: white;
  }

  &__leftBlock {
    position: relative;

    .main__uniqueApplications{
      width: 1109px;
      height: 888px;
      background-size: cover;
      -webkit-clip-path: url(#main-clip-path);
      clip-path: url(#main-clip-path);
      background: url('../../assets/img/main.png') center/cover;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color:rgba(0, 0, 0, 0.25);
        
      }

      h2 {
        font-family: $Montserrat;
        font-weight: 400;
        font-size: 64px;
        line-height: 109%;
        letter-spacing: 0.05em;
        color: #f4f4f4;
        width: 545px;
        padding: 320px 0 0 32px;
        position: relative;
        z-index: 1;

        span {
          animation: blink 2s infinite;
        }
        @keyframes blink {
          0% { opacity: 1 }
          50% { opacity: 0.2 }
          100% { opacity: 1 }
         }
      }
    }

    .main__projects {
      position: absolute;
      top:50%;
      right: 0;
      border-radius: 35px;
      width: 492px;
      height: 442px;
      background:  linear-gradient(147deg, rgb(0, 121, 140, 0.7) 28.96%, rgb(45, 129, 183, 0.7) 39%, rgb(85, 123, 187, 0.7) 45.86%, rgb(170, 148, 197, 0.7) 66.57%);
      display: flex;
      flex-direction: column;
      align-items: end;
      padding:98px 65px 96px 94px;

      .projects__header {
        font-family: $Montserrat;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.02em;
        text-align: right;
        color: white;
      }

      .projects__mobileArrowButton {
        display: none;
      }

      .projects__arrowButton {
        position: relative;
        margin-top:125px;
        cursor: pointer;

        &:hover {
          transition: 0.5s;
          transform: rotate(-30deg);
        }
      }
    }
  }
  &__rightBlock {
    
    .main__projects {
      display: none;
      position: absolute;
      top:50%;
      right: 0;
      border-radius: 35px;
      width: 492px;
      height: 442px;
      background:  linear-gradient(147deg, rgb(0, 121, 140, 0.7) 28.96%, rgb(45, 129, 183, 0.7) 39%, rgb(85, 123, 187, 0.7) 45.86%, rgb(170, 148, 197, 0.7) 66.57%);
      flex-direction: column;
      align-items: end;
      padding:98px 65px 96px 94px;

      .projects__arrowButton {
        margin-top:125px;
        cursor: pointer;

        &:hover {
          transition: 0.5s;
          transform: rotate(-30deg);
        }
      }
    }

    .main__team {
      border-radius: 45px;
      width: 676px;
      height: 411px;
      background: linear-gradient(217deg, rgba(214, 96, 109, 0.85) 19%, #e59047 50.73%, rgba(229, 185, 71, 0.75) 86.03%, rgba(239, 178, 45, 0.5) 100%, rgba(239, 178, 45, 0) 100%);
      padding:94px 116px 0 60px;
      overflow: hidden;
      position: relative;

      .team__arrowButton {
        position: absolute;
        bottom: 120px;
        right: 200px;
        cursor: pointer;

        &:hover {
          transition: 0.5s;
          transform: rotate(-30deg);
        }
      }

      .team__mobileArrowButton {
        display: none;
      }

      .team__humans {
        position: relative;
        div{
          position: absolute;
        }
        .human1 {
          top: -90px;
          right: -130px;
        }
        .human2 {
          top: 55px;
          right: -85px;
        }
        .human3 {
          top: 195px;
          right: -50px;
        }
        .human4 {
          top: 250px;
          right: 110px;
        }
        .human5 {
          top: 265px;
          right: 265px;
        }
      }

    }
    .main__office {
      margin-top:35px;
      background: url('../../assets/img/office.png') center/cover;
      border-radius: 45px;
      width: 676px;
      height: 442px;
      position: relative;
      
      span {
        text-align: center;
        color: #fff;
        font-family: $Pridi;
        font-weight: 300;
        font-size: 16px;
        position: absolute;
        border-radius: 5px;
        letter-spacing: 0.02em;
      }
      .office__firstMessage {
        padding: 5px;
        height: 35px;
        backdrop-filter: blur(27px);
        background:  rgba(162, 162, 162, 0.3);
        top: 53px;
        left: 140px;

        &::before, &::after{
          content: "";
          display: block;
          height: 1px;
          background: #fff;
          position: absolute;
        }
        &:before {
          width: 139px;
          right: 0;
          top: 40px;
        }
        &:after {
          right: 102px;
          top: 83px;
          transform: rotate(-63deg);
          width: 105px;
        }
      } 
      .office__secondMessage {
        padding: 5px;
        height: 35px;
        backdrop-filter: blur(27px);
        background:  rgba(162, 162, 162, 0.3);
        top:95px;
        right:250px;

        &:before,&:after{
          content: "";
          display: block;
          height: 1px;
          background: #fff;
          position: absolute;
        }
        &:before {
          width: 165px;
          right: -30px;
          top: 40px;
        }
        &:after {
          right: -60px;
          top: 56px;
          transform: rotate(51deg);
          width: 46px;
        }
      }
      .office__answer {
        width: 78px;
        left: 175px;
        bottom: 140px;
      }
    }
  }
}

@media only screen and (max-width: 1900px) and (min-width: 1381px) {
  .main {
    display:flex;
    align-items: center;
    justify-content: center;
    column-gap:33px;

    &__leftBlock {

      .main__uniqueApplications{
        width: 900px;
        height: 720px;
        -webkit-clip-path: none;
        clip-path: none;
        border-radius: 45px;

        &::before {
          content: none
        }
        
        h2 {
          padding: 135px 0 0 45px;
          
        }
      }

      .main__projects {
        display: none;
      }
    }

    &__rightBlock {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap:15px;

      .main__team {
        width: 580px;
        height: 225px;
        padding-top: 40px;

        .team__header {
          font-size: 18px;
        }

        .team__arrowButton {   
          bottom: 30px;
          right: 400px;

          &:hover {
            transition: 0.5s;
            transform: rotate(-30deg);
          }
        }

        .team__humans {
          .human1 {
            top:-45px;
          }
          .human2 {
            top: 65px;
            right: -60px;
          }
          .human3 {
            top: 130px;
            right: 55px;
          }
          .human4 {
            display: none;
          }
          .human5 {
            display: none;
          }
        }
      }

      .main__projects {
        display: flex;
        position: static;
        border-radius: 45px;
        width: 580px;
        height: 225px;
        padding-top:40px;

        .projects__arrowButton {
          margin-top:50px;
          
        }
      }
      .main__office {
        margin-top:0;
        width: 580px;
        height: 225px;

        .office__firstMessage {
          left:95px;
          top:20px;
          &::after {
            width: 35px;
            top: 50px;
          }
          &::before {
            content: none;
          }
        }
        .office__secondMessage {
          top: 40px;
          right: 192px;
          &::after {
            width:10px;
            right: -7px;
            top: 38px;
          }
          &::before {
            content: none
          }
        }
        .office__answer {
          left: 140px;
          bottom: 30px;
          
        }
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .main {

    &__leftBlock {

      .main__uniqueApplications{
        width: 600px;
        
        h2 {
          font-size: 44px;
          padding-top: 280px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1380px){
  .main {
    flex-direction: column;
    align-items: center;
    &__leftBlock {

      .main__uniqueApplications {
        width: 738px;
        height: 590px;
        -webkit-clip-path: url(#main-clip-path);
        clip-path: url(#main-clip-path);
        
        h2 {
          font-size: 35px;
          width: 310px;
          padding-top: 210px;
        }
      }
      .main__projects {
        height: 280px;
        width: 314px;
        top: auto;
        bottom: 0;
        padding: 62px 53px 0 0;

        .projects__header {
          font-size: 15px;
          padding: 0
        }

        .projects__mobileArrowButton {  
          position: relative;
          margin-top:100px;
          display: block;
        }
  
        .projects__arrowButton {
          display: none;
  
        }
      }
    }

    &__rightBlock {
      display: flex;
      align-items: center;
      column-gap: 39px;
      margin-top: 34px;

      .main__team {
        border-radius: 25px;
        width: 385px;
        height: 286px;
        padding: 53px 46px 33px 46px;
        background: (217deg, rgba(214, 96, 109, 0.85) 35%, #e59047 68.73%, rgba(229, 185, 71, 0.75) 94.03%, rgba(239, 178, 45, 0.5) 99%, rgba(239, 178, 45, 0) 100%);

        .team__humans {
          .human1 {
            width: 50px;
            height: 50px;
            top: -55px;
            right: -48px;
            img {
              width: 50px;
              height: 50px;
            } 
          }
          .human2 {
            top: 195px;
            right: 205px;
            width: 50px;
            height: 50px;
            img {
              width: 50px;
              height: 50px;
            } 
          }
          .human3 {
            top: 35px;
            right: -10px;
            width: 50px;
            height: 50px;
            img {
              width: 50px;
              height: 50px;
            } 
          }
          .human4 {
            width: 50px;
            height: 50px;
            top: 145px;
            right: 115px;
            img {
              width: 50px;
              height: 50px;
            } 
          }
          .human5 {
            width: 50px;
            height: 50px;
            top: 145px;
            right: -25px;
            img {
              width: 50px;
              height: 50px;
            } 
          }
        }
        .team__header {
          font-size: 15px;
          text-align: start;
        }

        .team__mobileArrowButton {
          display: inline-block;
          position: relative;
          top: 105px;
          left: 200px;
        }
        
        .team__arrowButton {
          display: none;
        }
  
      }
      .main__office {
        border-radius: 25px;
        width: 314px;
        height: 286px;
        margin:0;
        background-size: 130% 100%;
        background-position: left;

        span {
          font-size: 10px;
          border-radius: 5px;
          padding: 5px;
        }

        .office__firstMessage {
          height: 23px;
          top: 90px;
          left: 100px;
          width: auto;
          min-width: 79px;
          &:after {
            width: 26px;
            top:32px;
            right: 84px;
            transform: rotate(-40deg);
          }

          &:before {
            width: 95px;
            top:25px;
          }
        }
        .office__secondMessage {
          height: 23px;
          right: 15px;
          top:70px;
          width: auto;
          min-width: 90px;
          &:after {
            width: 24px;
            transform: rotate(-54deg);
            right: -8px;
            top: 32px;
          }

          &:before {
            width: 95px;
            right: -5px;
            top: 25px;
          }
        }
        .office__answer {
          left: 90px;
          bottom: 85px;
          width: 50px;
        }
      }
    }
  }
}


@media only screen and (max-width: 767px){
  .main {

    &__leftBlock {

      .main__uniqueApplications {
        width: 340px;
        height: 272px;
        
        h2 {
          font-size: 18px;
          width: 148px;
          padding: 10px 0 0 10px;
        }
      }
      .main__projects {
        border-radius: 17px;
        width: 148px;
        height: 135px;
        padding: 17px 20px 0 0;

        .projects__header {
          font-size: 10px;
          width: 100px;
        }

        .projects__mobileArrowButton {  
          margin-top:30px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
  
      }
    }

    &__rightBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      margin-top: 16px;

      .main__team {
        border-radius: 17px;
        width: 340px;
        height: 135px;
        padding: 20px 0px 0px 19px;
        background-color: linear-gradient(202deg, rgba(214, 96, 109, 0.85) 45%, #e59047 67.73%, rgba(229, 185, 71, 0.75) 93.03%, rgba(239, 178, 45, 0.5) 100%, rgba(239, 178, 45, 0) 100%);

        .team__humans {
          .human1 {
            width: 28px;
            height: 28px;
            top: -20px;
            right: 0px;

            img {
              width: 28px;
              height: 28px;
            } 
          }
          .human2 {
            top: 80px;
            right: 205px;
            width: 28px;
            height: 28px;

            img {
              width: 28px;
              height: 28px;
            } 
          }
          .human3 {
            top: 60px;
            right: 45px;
            width: 28px;
            height: 28px;
            img {
              width: 28px;
              height: 28px;
            } 
          }
          .human4 {
            width: 28px;
            height: 28px;
            top: 70px;
            right: 135px;
            img {
              width: 28px;
              height: 28px;
            } 
          }
          .human5 {
            width: 28px;
            height: 28px;
            top: 30px;
            right: 10px;
            img {
              width: 28px;
              height: 28px;
            } 
          }
        }
        .team__header {
          font-size: 10px;
          width:250px;
        }

        .team__mobileArrowButton {    
          top: 45px;
          left: 205px;
      
          svg {
            width: 20px;
            height: 20px;
          }
        }
  
      }
      .main__office {
        border-radius: 17px;
        width: 340px;
        height: 135px;
        background-size: 100% 130%;

        span {
          font-size: 8px;
          border-radius: 5px;
          padding: 5px;
        }

        .office__firstMessage {
          top: 15px;
          left: 90px;
        }
        .office__secondMessage {
          right: 70px;
          top: 5px;

        }
        .office__answer {
          left: 75px;
          bottom: 25px;
        }
      }
    }
  }
}