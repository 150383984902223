@import "../../utils/styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px 0 182px;
  position: sticky;
  top:0;
  z-index: 5;
  background-color: rgb(255, 255, 255);
  height: 120px;
  max-width: 1920px;
  margin: 0 auto;
  
  &__items {
    display: flex;
    align-items: center;
    column-gap:90px
  }
  .burgerButton{
    display: none;
  }
  &__item {
    font-family: $Montserrat;;
    font-weight: 400;
    font-size: 20px;
    color: $darkGreen;
    cursor: pointer;
    text-decoration: none;
    position: relative;
  
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      transform: translateX(50%);
      top:35px;
      background-color: $darkGreen;
      height: 1px;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    }



    &_active {
      position: relative;
 
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $darkGreen;
        top: 35px;
        right: 50%;
        transform: translateX(50%);
        position: absolute;
      }
    }
  }

  &__button {
    font-family: $Montserrat;
    border: 2px solid $darkGreen;
    border-radius: 5px;
    background-color: inherit;
    padding: 10px 25px;
    width: 153px;
    font-weight: 400;
    font-size: 20px;
    color: $darkGreen;
    margin-left: 12px;
    cursor: pointer;
    text-decoration: none;
  }
  
}

@media (hover: hover) {
  .header {
    &__item {
      &:hover::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
    }
  }
}
@media (hover: none) {
  .header {
    &__item {
      &:active::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .header {
    padding: 16px 20px 26px 62px;
    
    &__items {
      column-gap: 40px;
    }
    &__item {
      font-size: 18px;
    }
    &__button {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1380px) {
  .header {
    padding: 17px 12px 18px 13px;
    max-width: 767px;

    svg {
      width: 60px;
      height: 60px;
    }
    &__items {
      column-gap: 20px;
    }
    &__item {
      font-size: 15px;
    }
    &__button {
      border: 1px solid $darkGreen;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 7px 15px;
      width: 93px;
      height: 29px;
      font-size: 15px;
    }
  }
}


@media only screen and (max-width: 767px) {
  .header {
    padding: 10px 12px 10px 13px;
    max-width: 360px;
    height: auto;

    svg {
      width: 28px;
      height: 28px;
    }
    &__items {
      &_close {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:10px;
      background-color: #fff;
      padding: 28px 42px;
      border-radius: 17px;
      position: absolute;
      z-index:10;
      right:0;
      top:40px;
      align-items: start;
      a {
        font-size: 14px;
      }
      
    }
    &__item {
      &::before {
        content: none
      }
  
      &_active {
   
        &::after {
          content: none
        }
      }
    }
    &__button {
      border: none;
      align-items: start;
      padding: 0;
      margin:0;
      width: auto;
      height: auto;
      font-size: 10px;
    }
    
    .burgerButton {
      display: block;
      width: 18px;
      height: 18px;
      transition-duration: 0.5s;
      
      .icon {
        transition-duration: 0.5s;
        position: absolute;
        height: 1px;
        width: 18px;
        background-color: #00726a;
        
        &:before{
          transition-duration: 0.5s;
          height: 1px;
          width: 18px;
          position: absolute;
          top:6px;
          background-color: #00726a;
          content: "";
        }
        
        &:after{
          transition-duration: 0.5s;
          height: 1px;
          width: 18px;
          position: absolute;
          top: 12px;
          background-color: #00726a;
          content: "";
        }
      }
      
      &.burgerButton__open {
        .icon {
          transition-duration: 0.5s;
          background: transparent;
          
          &:before{
            transform: rotateZ(45deg) scaleX(1.25) translate(2.5px, 1.5px);
          }
    
          &:after{
            transform: rotateZ(-45deg) scaleX(1.25) translate(2px, -2px);
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

}