@import "../../utils/styles/variables.scss";

.vacancies {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 156px;
  row-gap: 50px;

  h2 {
    font-family: $Montserrat;
    font-weight: 400;
    font-size: 48px;
    line-height: 115%;
    letter-spacing: 0.05em;
    color: $darkBrown;
    margin-bottom: 35px;
    margin-left:47px;
  }

  .vacancies__list {
    display: flex;
    flex-direction: column;
    gap:85px;
    width: 730px;

    .vacancy {
      display: flex;
      flex-direction: column;
      align-items: start;

      .vacancy__name {
        font-family: $Montserrat;
        font-weight: 400;
        font-size: 28px;
        line-height: 71%;
        letter-spacing: 0.02em;
        color: $darkGreen;
        position: relative;
        margin-bottom: 20px;

        &::after {
          position: absolute;
          content: '';
          width: 727px;
          height: 1px;
          bottom:-10px;
          left:0;
          background-color: $lightBrown;
        }
      }
      .vacancy__info {
        display: flex;
        column-gap: 35px;
        align-items: center;

        span {
          font-family: $Montserrat;
          font-weight: 400;
          font-size: 16px;
          line-height: 156%;
          color: $darkBrown;
          position: relative;
          display:flex;
          align-items: center;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            left:-10px;
            background-color: $darkBrown;

          }
          &:first-child{
            &:before{
              content: none
            }
          }
        }
      }
      .vacancy__skills {
        margin-top:5px;
        display: flex;
        align-items: center;
        column-gap: 35px;

        span {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 13px;
          line-height: 154%;
          letter-spacing: 0.02em;
          color: $darkBrown;
          position: relative;
          display:flex;
          align-items: center;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            left:-10px;
            background-color: $darkBrown;

          }
          &:first-child{
            &:before{
              content: none
            }
          }
        }
      }
    }
  }
  .vacancies__content {
    position: relative;

    .vacancies__all {
      position: absolute;
      border-radius: 35px;
      width: 279px;
      height: 218px;
      top:0;
      left:0;
      background: linear-gradient(147deg, #00798c 30.96%, #557bbb 76.86%);
      display: flex;
      flex-direction: column;
      align-items: end;
      padding:60px 50px;
      row-gap: 20px;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        border-radius: 35px;
        background-color: rgba(255, 255, 255, 0.25);
        
      }
      span {
        font-family: $Montserrat;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        width: 190px;
        text-align: start;
      }
    }
    .vacancies__mobileLink {
      display: none;
    }
    .vacancies__link {
      transform: rotate(45deg);
      cursor: pointer;
      text-decoration: none;
      position: relative;
      z-index: 1;

      &:hover{
        transition: 0.5s;
        transform: rotate(0deg);
      }

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .vacancies__office {    
      position: relative;
  
      .office__img{
        width: 794px;
        height: 830px;
        -webkit-clip-path: url(#vacancies-clip-path);
        clip-path: url(#vacancies-clip-path);
        background: url('../../assets/img/officeVacanciesClip.png') center/cover no-repeat;

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          background-color:rgba(217, 217, 217, 0.15);
          
        }
      }
      

      .vacancies__firstMessages {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: end;
        top: 25px;
        right: 220px;
        width: 100%;

        &::after {
          content: '';
          position: absolute;
          transform: rotate(50deg);
          height: 1px;
          width: 101px;
          background-color: #f4f4f4;
          top:68px;
          right:-70px;
        }

        span {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: #fff;
          backdrop-filter: blur(27px);
          background: rgba(255, 255, 255, 0.1);
          padding: 5px;
          border-radius: 5px;
          position: absolute;

          &:after {
            content: '';
            left: 0;
            top:32px;
            position: absolute;
            height: 1px;
            background-color: #f4f4f4;
          }
        }

        .firstMessage {
          right:30px;
          top:0;
          position: relative;
          &:after {
            width: 214px;
          }
        }
        .secondMessage {
          top:35px;
          right: 15px;
          &:after {
            width: 180px;
          }
        }
        .thirdMessage {
          top:70px;
          &:after {
            width: 278px;
          }
        }
      }
      
      .vacancies__secondMessages {
        position: absolute;
        width: 200px;
        display: flex;
        top: 225px;
        left: 300px;
        flex-direction: column;
        align-items: center;

        &::after {
          content: '';
          position: absolute;
          transform: rotate(-63deg);
          height: 1px;
          width: 101px;
          background-color: #f4f4f4;
          top: 72px;
          right: 92px;
        }

        span {
          font-family: $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.02em;
          color: #fff;
          backdrop-filter: blur(27px);
          background: rgba(255, 255, 255, 0.1);
          padding: 5px;
          border-radius: 5px;
          position: absolute;

          &:after {
            content: '';
            left: -22px;
            top:32px;
            position: absolute;
            height: 1px;
            background-color: #f4f4f4;
          }
        }

        .firstMessage {
          right:-10px;
          top:0;
          
          position: relative;
          &:after {
            left: -12px;
            width: 62px;
          }
        }
        .secondMessage {
          top:35px;
          right: -35px;
          &:after {
            width: 196px;
          }
        }
        .thirdMessage {
          top:70px;
          right: 30px;
          &:after {
            width: 155px;
            left:-22px;
          }
        }
      }
    }
  }
 
}

@media only screen and (max-width: 1380px){
 .vacancies {
    h2 {
      font-size: 24px;
      margin-left: 0;
    }
    .vacancies__list {
      gap:35px;

      .vacancy {
        display: flex;
        flex-direction: column;
        align-items: start;
        
        .vacancy__name {
          font-size: 15px;
          &::after {
            width: 500px;
          }
        }
        .vacancy__info {
          span {
            font-size: 12px;
          }
        }
        .vacancy__skills {
          span {
            font-size: 12px;
          }
        }
      }
    }
    .vacancies__content {
      .vacancies__link {
        display: none;
      }
      .vacancies__mobileLink {
        transform: rotate(45deg);
        display: block;
        text-decoration: none;
        position: relative;
        z-index: 1;
  
        svg {
          width: 21px;
          height: 21px;
        }
      }
      .vacancies__all {
        padding: 44px;
        border-radius: 13px;
        width: 238px;
        height: 107px;
        right: 0;
        left: auto;
        flex-direction: row;
        align-items: start;

        span {
          font-size: 15px;
          text-align: center;
        }

        &:before {
          border-radius: 13px;
        }
      }
      .vacancies__office {    

        .office__img{
          width: 738px;
          height: 406px;
          -webkit-clip-path: url(#vacancies-tabletClip-path);
          clip-path: url(#vacancies-tabletClip-path);
          background: url('../../assets/img/officeVacancies.png') center/cover no-repeat;
          background-size: 100% 100%;
        }

        .vacancies__firstMessages {
          align-items: start;
          left: 85px;
          top: 55px;
          width: 200px;
          span {
            backdrop-filter: blur(27px);
            background: rgba(255, 255, 255, 0.1);
            border-radius: 5px;
            padding: 5px;
            height: 23px;
            font-size: 10px;
            &::after {
              top:25px;
              left:-17px;
            }
          }
          .firstMessage {
            left: 0px;
            &:after {
              width: 145px;
            }
          }
          .secondMessage {
            right:auto;
            left: -15px;
            &:after {
              width: 173px;
            }
          }
          .thirdMessage {
            left: -35px;
            &:after {
              width: 200px;
            }
          }
          &::after {
            left: -80px;
            width: 100px;
            right: auto;
            transform: rotate(-62deg);
          }
        }
        .vacancies__secondMessages {
          align-items: start;
          top: 110px;
          left: 270px;
          span {
            backdrop-filter: blur(27px);
            background: rgba(255, 255, 255, 0.1);
            border-radius: 5px;
            padding: 5px;
            height: 23px;
            font-size: 10px;
            width: auto;

            &::after {
              width: 121px;
              top:25px;
              left:-17px;
            }
          }
          .firstMessage {
            left: 85px;
            top: 5px;
            &:after {
              width: 78px;
            }
          }
          .secondMessage {
            right:auto;
            left: 75px;
            &:after {
              width: 146px;
            }
          }
          .thirdMessage {
            left: 60px;
            right: auto;
            &:after {
              width: 177px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px){
  .vacancies {
    row-gap: 35px;

     h2 {
       font-size: 19px;
     }
     .vacancies__list {
       max-width: 310px;

       .vacancy {
         .vacancy__name {
           font-size: 15px;
           &::after {
             width: 310px;
           }
         }
         .vacancy__info {
           span {
             font-size: 11px;
             &:nth-child(2) {
                display: none;
              }
           }
         }
         .vacancy__skills {
           span {
             font-size: 10px;
           }
         }
       }
     }
     .vacancies__content {
    
       .vacancies__mobileLink {
         transform: rotate(45deg);
         display: block;
         text-decoration: none;
         position: relative;
         z-index: 1;
   
         svg {
           width: 21px;
           height: 21px;
         }
       }
       .vacancies__all {
         border-radius: 10px;
         width: 165px;
         height: 49px;
         right: 0;
         left: auto;
         flex-direction: row;
         align-items: center;
         padding: 12px;
         background: linear-gradient(21deg, #00798c 2.96%, #557bbb 65.86%);

         span {
           font-size: 12px;
           text-align: center;
           line-height: 2px;
         }
 
         &:before {
           border-radius: 10px;
         }
       }
       .vacancies__office {    
 
         .office__img{
           width: 340px;
           height: 164px;
           -webkit-clip-path: url(#vacancies-mobileClip-path);
           clip-path: url(#vacancies-mobileClip-path);

         }
 
         .vacancies__firstMessages {
           left: 41px;
           top: 50px;

           span {
             height: 20px;
             font-size: 8px;
             &::after {
               content: none;
             }
           }

           .firstMessage {
            top:20px;
            left: 0px;
             &:after {
               width: 145px;
             }
           }
           .secondMessage {
             right:auto;
             left: 0px;
             top:45px;
             &:after {
              content: none;
             }
           }
           .thirdMessage {
             left: 0px;
             &:after {
              content: none;
             }
           }
           &::after {
            content: none;
           }
         }
         .vacancies__secondMessages {
           align-items: start;
           top: 50px;
           left: 200px;
           width: 85px;

           &::after {
            content: none
          }
           span {
             height: 20px;
             font-size: 8px;
             width: auto;
 
             &::after {
                content: none
             }
           }
           .firstMessage {
             left: 0px;
             top: 20px;
             &:after {
              content: none;
             }
           }
           .secondMessage {
             right:auto;
             left: 0px;
             top: 45px;
             &:after {
              content: none;
             }
           }
           .thirdMessage {
             left: 0px;
             right: auto;
             &:after {
              content: none;
             }
           }
         }
       }
     }
   }
 }

 @-moz-document url-prefix() {
  @media only screen and (max-width: 1380px) and (min-width: 767px){
    .vacancies {
 
       .vacancies__content {
         
         .vacancies__all {
           height: 135px;
           
         }
       }
     }
   }
}