@import "../../utils/styles/variables.scss";

.projects {

  &__list {
    margin-top:79px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 1670px;

    .project {
      display: flex;
      align-items: center;
      column-gap: 15px;
      max-width: 536px;
      padding: 57px 60px;
      position: relative;

      &::after,&::before {
        content: '';
        position: absolute;
        background-color:  $lightBrown;
      }
      &::after {
        width:100%;
        height: 1px;
        top:100%;
      }
      &::before {
        height: 120px;
        width: 1px;
        left: 0px;
      }
      &:last-child {
        .project__description {
          max-width:331px;
        }
      }
      &:nth-child(3n + 1) {
        &::before{
          content: none;
        }
      }

      .project__name {
        font-family: $Pridi;
        font-weight: 300;
        font-size: 14px;
        text-align: center;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 67px;
      }

      .project__description {
        font-family: $Pridi;
        font-weight: 300;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.02em;
        color: $darkBrown;
        max-width: 330px;
      }
    }
  }
  
}

@media only screen and (max-width: 1712px) {
  .projects {
  
    &__list {

      .project {
        width: 500px;

        &:last-child {
          .project__description {
            max-width: auto;
            width:260px;
          }
        }

        .project__description {
          font-size: 12px;
          width:247px;
        }
        
       
        
      }
    }
  }
}
@media only screen and (max-width: 1616px) {
  .projects {
  
    &__list {

      .project {
        
        &:last-child {
          .project__description {
            max-width: auto;
            width:260px;
          }
        }

        &:nth-child(3n + 1) {
          &::before{
            content: '';
          }
        }
        &:nth-child(2n + 1) {
          &::before{
            content: none;
          }
        }
        
      }
    }
  }
}
@media only screen and (max-width: 1380px) {
  .projects {
    .sectionHeader {
      &__message {
        max-width: 370px; 
      }
    }
    &__list {
      column-gap:54px;

      .project { 
        height: 90px;
        padding:0;
        width: auto;
        &::after {
          width:310px;
          height: 1px;
          top:100%;
        }
        &::before {
          content: none;
        }
        &:nth-child(3n + 1) {
          &::before{
            content: none;
          }
        }
        &:last-child {
          &::after {
            top:98%;
          }
        }
        .project__name {
          width:50px;
          height: 50px;
          font-size: 10px;
        }
  
        .project__description {
          font-size: 12px;
          width:247px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .projects {

    .sectionHeader {
      &__text {
        width: 195px;
      }
      &__message {
        width: 135px;
      }
    }
    &__list {
      margin-top:40px;
      
      &_hide {
        overflow: hidden;
        max-height: 365px;
      }
      .project { 
        width: 310px;

        .project__name {
          width:47px;
          height: 47px;
        }

        &:nth-child(3) {
          .project__description {
            width:200px;
          }
        }
        &:last-child {
          .project__description {
            width:207px;
          }
        }
        .project__description {
          font-size: 10px;
          width:205px;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  @media only screen and (max-width: 767px) {
    .projects {

      &__list {
        margin-top:40px;
        
        .project { 
  
          &:nth-child(1) {
            .project__description {
              width:210px;
            }
          }
          &:nth-child(3) {
            .project__description {
              width:215px;
            }
          }
          &:last-child {
            .project__description {
              width:220px;
            }
          }
          .project__description {
            font-size: 10px;
            width:215px;
          }
        }
      }
    }
  }
}
