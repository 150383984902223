.content {
  max-width: 1920px;
  padding: 0px 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}

@media only screen and (max-width: 1380px){ 
  .content {
    padding: 0px 15px;
    max-width: 767px;
  }
}

@media only screen and (max-width: 767px){ 
  .content {
    padding: 0px 10px;
    max-width: 360px;
    row-gap: 20px;
  }
}