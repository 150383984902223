@import "../../utils/styles/variables.scss";

.technologies {
  .technologies__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 100px;
    column-gap: 140px;
    max-height: 790px;
    padding: 50px 60px 20px;
    background: no-repeat center url('../../assets/img/Technologies.png');

    .technology {
      max-width: 740px;
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      height: 90px;

      &::before {
        content: '';
        position: absolute;
        top: 28px;
        width: 100%;
        height:1px;
        background-color: $lightBrown;
      }

      .technology__name {
        font-family:  $Montserrat;
        font-weight: 400;
        font-size: 28px;
        line-height: 71%;
        letter-spacing: 0.02em;
        color: $darkBrown;
      }
      .technology__tools {
        display: flex;
        column-gap: 55px;
        flex-wrap: wrap;
        row-gap: 9px;
        overflow: hidden;

        .tool {
          font-family:  $Pridi;
          font-weight: 300;
          font-size: 16px;
          line-height: 125%;
          color: $darkBrown;
          position: relative;
          display: flex;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 4px;
            left: -15px;
            height: 4px;
            border-radius: 50%;
            background-color: $darkBrown;
          }
        }
      }
    }
    
    
  }
}

@media only screen and (max-width: 1820px){
  .technologies {
    margin-bottom: 60px;
    .technologies__list {
      max-height: 100%;
      background-size: 100% 100%;
      width: 720px;
      margin: 0 auto;
      padding: 60px 0;
      .technology {
        width: 740px;
      }
    }
  }
}

@media only screen and (max-width: 1380px){
  .technologies {
    .sectionHeader {
      &__message {
        max-width: 340px;
      }
    }
    
    margin-bottom: 60px;

    .technologies__list {
      padding-bottom: 0;
      background: none;
      margin: 0;
      row-gap: 50px;
      .technology {
        width: 445px;
        height: auto;

        .technology__name {
          font-size: 15px;
        }

        .technology__tools {

          .tool {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px){
  .technologies {
    margin-bottom: 60px;

    .sectionHeader {
      &__text {
        width: 200px;
      }
      &__message {
        width: 110px;
      }
    }


    .technologies__list {
      padding-bottom: 0;
      background: none;
      margin: 0;
      row-gap: 50px;
      width: 310px;


      &_hide {
        overflow: hidden;
        max-height: 500px;
      }

      .technology {
        width: 310px;
        height: auto;

        &::before {
          top: 15px;
        }

        .technology__name {
          font-size: 12px;
        }

        .technology__tools {
          .tool {
            font-size: 10px;
            &:before {
              width: 2px;
              height: 2px;
            }
          }
        }
      }
    }
  }
}