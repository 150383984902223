body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
}


.ymaps3x0--default-marker__title-wrapper {
  max-width: 100% !important;
}

.ymaps3x0--default-marker__icon-dot {
  position: absolute;
  background-color: #00726a !important;
  bottom: 0;
}
.ymaps3x0--default-marker__title-wrapper {
  position: relative;

  &::before {
    content: url("../../assets/svg/office.svg");
    z-index: 1;
    position: absolute;
    left: -55px;
    z-index: 1;
    position: absolute;
    top: -18px;
  }
}