@import "../../utils/styles/variables.scss";

.sectionHeader {
  display: flex;
  align-items: center;
  padding: 25px 0 0 95px;
  column-gap: 25px;

  &__text {
    font-family: $Montserrat;
    font-weight: 400;
    font-size: 48px;
    line-height: 115%;
    letter-spacing: 0.05em;
    color: $darkBrown;
    width: 525px;
  }
  &__message{
    font-family: $Montserrat;
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    letter-spacing: 0.02em;
    color: #00726a;
    max-width: 830px;
  }
}

@media only screen and (max-width: 1600px) {
  .sectionHeader {
    justify-content: space-between;
    &__message {
      max-width: 600px;
    }
  }
}

@media only screen and (max-width: 1380px) {
  .sectionHeader {
    padding: 20px 0 0 10px;
    &__text {
      font-size: 24px;
      width: 253px;
    }
    &__message {
      max-width: 450px;
      font-size: 12px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .sectionHeader {
    padding: 0;
    &__text {
      font-size: 19px;
    }
    &__message {
      font-size: 8px;
    }
  }
}