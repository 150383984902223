@import "../../utils/styles/variables.scss";

.footer {
  max-width: 1920px;
  margin:0 auto;
  padding: 88px 80px 67px 80px;
  display: flex;
  justify-content: space-between;

  .footer__contacts {
    display: flex;
    flex-direction: column;
    align-items: end;

    span {
      margin-bottom: 14px;
      font-family: $Montserrat;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: $darkGreen;
    }
    .contact__icons {
      text-decoration: none;
      display: flex;
      column-gap: 15px;
    }
  }
  .footer__copyrights {
    display: flex;
    flex-direction: column;

    .copyrights__header {
      font-family: $Montserrat;
      font-weight: 400;
      font-size: 13px;
      color: $darkGreen;
      margin-bottom: 8px;
    }
    .copyrights__subHeader {
      font-family:  $Pridi;
      font-weight: 300;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: $darkBrown;
    }
  }
}

@media only screen and (max-width: 1380px) { 
  .footer {
    max-width: 767px;
    padding: 39px 15px;
    .footer__contacts {

      span {
        font-size: 12px;
      }

    
    }
    .footer__copyrights {
    
      .copyrights__header {
        font-size: 12px;

      }
      .copyrights__subHeader {

        font-size: 11px;

      }
    }
  }
}

@media only screen and (max-width: 767px) { 
  .footer {
    max-width: 360px;
    padding: 15px 10px 10px;
    align-items: end;
    .footer__contacts {

      span {
        display: none;
        font-size: 10px;
      }
    
    }
    .footer__copyrights {
    
      .copyrights__header {
        font-size: 10px;
        margin-bottom: 5px;

      }
      .copyrights__subHeader {

        font-size: 8px;

      }
    }
  }
}