@import "../../utils/styles/variables.scss";

.contacts {

  .content {
    display: flex;
    flex-direction: row;
    column-gap: 51px;
    margin-top: 50px;
    
    span {
      font-family: $Montserrat;
      font-weight: 400;
      font-size: 20px;
      line-height: 125%;
      color: $darkGreen;
    }
    .address__item {
      display: flex;
      flex-direction: column;

      .address__subHeader {
        margin-top: 12px;
        text-decoration: none;
        font-family: $Pridi;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: $darkBrown;
        line-height: 25px;
      }
    }
  
    .contacts__map {
      border: 1px solid #afaaa3;
      border-radius: 50px;
      width: 1005px;
      overflow: hidden;
      height: 550px;
      cursor: pointer;
    }
    .contacts__info {
      .address {
        display: flex;
        flex-direction: column;
        font-style: normal;
        gap: 34px;
        padding: 33px 85px;

      }
    }
    
    .contacts__mobileCooperation {
      display: none;
    }

    .contacts__cooperation{
      padding: 36px 85px;
      display: flex;
      flex-direction: column;
      row-gap:34px;

      .cooperation__header {
        line-height: 100%;
      }
      .cooperation__contacts {
        display: flex;
        column-gap: 23px;
      }
    }
  }
}
@media only screen and (max-width: 1700px) {
  .contacts {
    .content {
      .contacts__info {
        .address {
          padding: 36px 25px;
        }
      }
      .contacts__cooperation {
        padding: 36px 25px;
      }
    }

  }
}
@media only screen and (max-width: 1380px) {
  .contacts {
    .content {
      display: flex;
      flex-direction: column-reverse;
      margin-top:0;
      gap: 0;

      span {
        font-size: 15px;
      }
      #YMap {
        margin: 0 auto;
        width: 100%;
        max-width: 767px;
        border-radius: 13px;
      }
      .contacts__info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 767px;
        margin: 0 auto;
        .address {
          padding: 36px 0px;
          .address__item {
            .address__subHeader {
              font-size: 12px ;
            }
          }
        }
      }
      .contacts__cooperation {
        padding: 36px 0px;
        .cooperation__contacts {
          .address__item {
            .address__subHeader {
              font-size: 12px ;
            }
          }
        }
      }
      
    }

  }
}

@media only screen and (max-width: 767px) {
  .contacts {
    .content {
      padding: 0;
      span {
        font-size: 12px;
      }

      #YMap {
        margin: 22px auto;
        width: 100%;
        max-width: 360px;
        border-radius: 5px;
        max-height: 200px;
      }
      .contacts__info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        .address {
          gap:16px;
          padding: 5px 0px;
          .address__item {
            .address__subHeader {
              margin-top: 5px;
              font-size: 10px;
              line-height: 130%;
            }
          }
        }
      }
      .contacts__cooperation {
        display: none;
      }

      .contacts__mobileCooperation {
        display: block;
        .cooperation__header {
          margin-bottom: 10px;
        }
        .cooperation__contacts {
          display: flex;
          margin-top: 10px;
          column-gap: 16px;
          .address__item {
            .address__subHeader {
              font-size: 10px;
              margin-top: 5px;
            }
          }
        }
      }
    }

  }
}
