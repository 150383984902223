@import "../../utils/styles/variables.scss";

.services {

  .sectionHeader {
    &__message {
      max-width: 800px;
    }
  }
  .servicies__more {
    display: none;
  }

  .services__list {
    display: flex;
    flex-wrap: wrap;
    gap:34px;
    margin-top:83px;
    justify-content: center;

    .service {
      width: 536px;
      height: 310px;
      border-radius: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 100px;
      gap:15px;
      text-align: start;
      position: relative;
      
      &:nth-child(3) {
        .service__subHeader {
          max-width: 271px;
        } 
      }

      &:nth-child(6) {
        .service__subHeader {
          max-width: 290px;
        } 
      }

      &:hover{
        &:after {
          background-color: rgba(203, 195, 186, 0.35);
          box-shadow: 7px 5px 5px rgba(203, 195, 186, 0.75);
        }
      }
      &:after {
        content: '';
        cursor: pointer;
        border-radius: 35px;
        position: absolute;
        background-color: rgba(203, 195, 186, 0.15);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        left:0;
        width: 100%;
        height: 100%;
      }

      span {
        color: #fff;
        position: relative;
        z-index: 2;
        font-weight: 400;
      }
      &__header {
        font-family: $Montserrat;
        font-size: 20px; 
      }

      &__subHeader {
        font-family: $Pridi;
        font-size: 16px;
        line-height: 125%;

      }
    }
  }
}

@media only screen and (max-width: 1380px) and (min-width: 767px) { 
  .services {
    .sectionHeader {
      &__message {
        max-width: 340px;
      }
    }
    .services__list {
      flex-direction: column;
      max-height: 675px;
      max-width: 738px;
      margin: 50px auto 0;
      
      .service {
        text-align: center;
        border-radius: 20px;
        width: 352px;
        height: 201px;
        padding:60px 14px 71px 14px;
        gap:20px;

        &:first-child {
          .service__subHeader {
            margin: 0 auto;
            max-width: 252px;
          } 
        }

        &:nth-child(3) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 212px;
          } 
        }

        &:nth-child(4) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 274px;
          } 
        }

        &:nth-child(5) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 274px;
          } 
        }

        &:nth-child(6) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 225px;
          } 
        }

        &:after {
          border-radius: 20px;
          top:0;
        }

        &__header {
          font-size: 15px;
        }
  
        &__subHeader {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) { 
  .services {

    .sectionHeader {
      &__text{
        width:125px;
      }
      &__message {
        width: 161px;
      }
    }


    .services__list {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      gap: 22px;
      flex-wrap: nowrap;
      

      &_hide {
        max-height: 435px;
        overflow: hidden;
        justify-content: start;
      }

      .service {
        text-align: center;
        border-radius: 13px;
        width: 290px;
        height: 90px;
        padding:20px 27px 19px 27px;
        gap:10px;

        &:after {
          border-radius: 13px;
          top:0;
        }

        &:first-child {
          .service__subHeader {
            margin: 0 auto;
            max-width: 205px;
          } 
        }

        &:nth-child(3) {
          .service__subHeader {
            margin: 0 auto;
            width: 178px;
          } 
        }

        &:nth-child(4) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 274px;
          } 
        }

        &:nth-child(5) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 220px;
          } 
        }

        &:nth-child(6) {
          .service__subHeader {
            margin: 0 auto;
            max-width: 185px;
          } 
        }

        &__header {
          font-size: 12px;
        }
  
        &__subHeader {
          font-size: 10px;
        }
      }
    }
  }
}

@-moz-document url-prefix() {

  @media only screen and (max-width: 767px) {
    .services {
  
      .services__list {
      
        .service {
         
          &:nth-child(3) {
            .service__subHeader {
              margin: 0 auto;
              width: 182px;
            } 
          }
  
        }
      }
    }
  }
}